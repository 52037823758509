import { useState, useEffect, useRef } from "react"
import { Switch, Route, Redirect, BrowserRouter, useParams, useHistory } from "react-router-dom"

import videojs from "video.js"
import axios from "axios"

import './app.sass';
import "./player.sass"

videojs.addLanguage('en', {"The media could not be loaded, either because the server or network failed or because the format is not supported.": "Could not load the stream"});


const LoginModule = () => {
  const [streamName, setStreamName] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const history = useHistory()
  const inputRef = useRef()

  // Focus the input area
  useEffect(() => {inputRef.current.focus()});

  const play = () => {
    if (!streamName){
      setErrorMsg("Stream ID must be specified")
      return
    }

    axios.get("https://live.nbla.xyz/" + streamName + ".m3u8")
    .then((response) => {
      history.push("/stream/" + streamName)
    })
    .catch(error => {
      setErrorMsg("This stream ID does not exist")
    })
  }

  return (
    <div className="row">
      <input
        ref={inputRef}
        type="text"
        placeholder="Stream ID"
        onChange={e => setStreamName(e.target.value)}
        onKeyPress={e => { if (e.key === "Enter") play() }}
      />
      <button onClick={play}>Play</button>

      <div className="errormsg">
        { errorMsg && <span> { errorMsg } </span> }
      </div>

    </div>
  )
} // End of LoginModule


const PlayerModule = () => {
  const {id} = useParams()
  const playerRef = useRef()

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    fluid: true,
    aspectRatio: "16:9",
    sources: [{
      src: "https://live.nbla.xyz/" + id +".m3u8",
      type: "application/x-mpegURL"
    }]
  }


  useEffect(() => {
    const player = videojs(playerRef.current, videoJsOptions, function onPlayerReady() {
      // console.log("Player is ready")
    });

    // Clean-up
    return () => {
      player.dispose();
    }
  });

  return (
    <div className="row">
      <div data-vjs-player>
        <video ref={playerRef} className="video-js" ></video>
      </div>
    </div>
  )
} // End of PlayerModule



const App = () => {
  return (
    <div className="container">
      <BrowserRouter>
      <Switch>
            <Route path="/" exact component={LoginModule}/>
            <Route path="/stream/:id" component={PlayerModule}/>
            <Route path="*"><Redirect to="/"></Redirect></Route>
      </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
